//GLOBAL - components from npm
import React from 'react';

//STYLES
import './text.scss'

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function Text({ children, weight, className, color }) {


    return (
        <div className={`text text--${weight} ${className ? className : ''} text--${color}`}>
            {children}
        </div>
    )
}
