//GLOBAL - components from npm
import React, {useEffect} from 'react';

//STYLES
import './terms-and-conditions.scss'

//COMPONENTS
import { Typography } from "../../components/General";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function TermsAndConditions() {
    //GENERAL
    
    
    //STATES
    
    
    //REDUX - Selectors
    
    
    //FUNCTIONS
    
    //USE EFFECTS
    useEffect(() => {
        document.title ='Knowbie - Termos e condições';
    },[])
    
    return (
        <div className="terms-and-conditions">
            <div className="terms-and-conditions-content">
                <div className="terms-and-conditions-content__title"><Typography.H1 color="white-gray-3">Termos Gerais de Uso</Typography.H1></div>
                <Typography.H1 color="white-gray-3">1. Relacionamento Contratual</Typography.H1>
                <p>Estes termos de uso (“Termos”) regem seu acesso e uso, como pessoa física, dentro do Brasil, de aplicativos, sites de Internet, conteúdos, bens e também serviços (os “Serviços”) disponibilizados pela <strong>WTF TECNOLOGIA LTDA.</strong>, sociedade de responsabilidade limitada, estabelecida no Brasil, com sede na Av. Tancredo Neves, 1543, Edf. Garcia D’Ávila, 7º andar, Sala 701, CEP 41.820-021, Caminho das Árvores, Salvador - BA, inscrita no CNPJ sob o nº. 24.362.966/0001-37 ou qualquer de suas afiliadas. </p>
                <p>POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</p>
                <p>Ao acessar e usar os Serviços informados nesses Termos, as partes concordam com as condições impostas, que estabelecem o relacionamento contratual entre o usuário (a) ALUNO OU MENTOR, e a WTF/KNOWBIE. Se não há concordância com estes Termos, não poderá acessar nem usar os Serviços.</p>
                <p>Mediante referido acesso e uso, estes Termos imediatamente encerram, substituem e superam todos os acordos, Termos e acertos anteriores entre a parte e qualquer Afiliada da WTF/KNOWBIE. A WTF/KNOWBIE poderá imediatamente encerrar estes Termos ou quaisquer Serviços em relação a parte ou, de modo geral, deixar de oferecer ou negar acesso aos Serviços ou a qualquer ponto deles, a qualquer momento e por qualquer motivo.</p>
                <p>Termos adicionais poderão se aplicar a determinados Serviços, tais como: condições para um evento, atividade ou promoção em particular. Os termos adicionais serão divulgados em relação aos respectivos Serviços.</p>
                <p>Os Termos adicionais são complementares e considerados parte integrante destes Termos para os efeitos dos respectivos Serviços. Termos adicionais prevalecerão sobre estes Termos em caso de conflito com relação aos referidos Serviços.</p>
                <p>A WTF/KNOWBIE poderá alterar os Termos relativos aos Serviços a qualquer momento. Aditamentos entrarão em vigor quando a WTF/KNOWBIE fizerem a postagem da versão atualizada dos Termos neste local ou das condições atualizadas ou Termos adicionais sobre o respectivo Serviço. O fato da parte continuar a acessar ou usar os Serviços após essa postagem representa seu consentimento em se vincular aos Termos alterados.</p>
                <p>A WTF/KNOWBIE é a controladora dos dados pessoais coletados em conexão com o uso dos Serviços no Brasil. Os seus dados pessoais são armazenados em nossos servidores com base em mecanismos aprovados sob as leis aplicáveis, e a coleta e utilização pela WTF/KNOWBIE de dados pessoais associados aos Serviços são feitas de acordo com o Aviso de Privacidade da WTF em consonância com a legislação vigente, que pode ser consultada em <a target="_blank" href="https://www.wtf.inf.br/politica.php">www.wtf.inf.br/politica.php</a>.</p>
                <Typography.H1 color="white-gray-3">2. Os Serviços</Typography.H1>
                <p>Os Serviços integram uma plataforma de tecnologia que permite aos (às) Usuários (as) de aplicativos móveis ou sites de Internet da WTF/KNOWBIE, fornecidos como parte dos Serviços (cada qual um “Aplicativo”), solicitar e programar uma sessão online que acontece em determinado dia e hora, através de uma plataforma de comunicação externa ao WTF/KNOWBIE, e que é comprada usando WTF/KNOWBIE Points, determinados pelo MENTOR.</p>
                <p>A moeda interna da ferramenta são os WTF/KNOWBIE POINTS. Uma moeda virtual usada para as transações dentro do WTF/KNOWBIE. SESSÃO PROMOVIDA: Uma sessão de mentoria que foi impulsionada para ser visualizada por mais USUÁRIOS. Podemos considerar os seguintes usuários:</p>
                <ul>
                    <li>ALUNO: Player que comprou uma sessão de mentoria.</li>
                    <li>MENTOR: Player que realiza uma sessão de mentoria.</li>
                </ul>
                <p>A PARTE RECONHECE QUE A WTF/KNOWBIE NÃO É FORNECEDORA DE BENS, NÃO PRESTA SERVIÇOS DE CURSOS, TUTORIA OU MENTORIA, NEM FUNCIONA COMO INSTITUIÇÃO DE ENSINO, E QUE TODOS ESSES SERVIÇOS DE CURSOS, TUTORIA OU MENTORIA SÃO PRESTADOS POR PARCEIROS INDEPENDENTES, QUE NÃO SÃO EMPREGADOS (AS) E NEM REPRESENTANTES DA WTF/KNOWBIE, NEM DE QUALQUER DE SUAS AFILIADAS.</p>
                <Typography.H2 color="white-gray-3">2.1 Licença.</Typography.H2>
                <p>Sujeito ao cumprimento destes Termos, a WTF/KNOWBIE outorga a parte uma licença limitada, não exclusiva, não passível de sublicença, revogável e não transferível para: (i) acesso e uso dos Aplicativos em seu dispositivo pessoal, exclusivamente para o seu uso dos Serviços; e (ii) acesso e uso de qualquer conteúdo, informação e material correlato que possa ser disponibilizado por meio dos Serviços, em cada caso, para seu uso pessoal, nunca comercial. Quaisquer direitos não expressamente outorgados por estes Termos são reservados à WTF/KNOWBIE e suas afiliadas licenciadoras.</p>
                <Typography.H2 color="white-gray-3">2.2 Restrições.</Typography.H2>
                <p>A parte não poderá: (i) remover qualquer aviso de direito autoral, direito de marca ou outro aviso de direito de propriedade de qualquer parte dos Serviços; (ii) reproduzir, modificar, preparar obras derivadas, distribuir, licenciar, locar, vender, revender, transferir, exibir, veicular, transmitir ou, de qualquer outro modo, explorar os Serviços, exceto da forma expressamente permitida pela WTF/KNOWBIE; (iii) decompilar, realizar engenharia reversa ou desmontar os Serviços, exceto conforme permitido pela legislação aplicável; (iv) conectar, espelhar ou recortar qualquer parte dos Serviços; (v) fazer ou lançar quaisquer programas ou scripts com a finalidade de sobrecarregar ou prejudicar indevidamente a operação e/ou funcionalidade de qualquer aspecto dos Serviços; ou (vi) tentar obter acesso não autorizado aos Serviços ou prejudicar qualquer aspecto dos Serviços ou seus sistemas ou redes correlatas.</p>
                <Typography.H2 color="white-gray-3">2.3 Prestação dos Serviços.</Typography.H2>
                <p>A parte reconhece que os Serviços podem ser disponibilizados sob diferentes marcas da WTF/KNOWBIE ou diferentes opções de solicitação associadas aos serviços de mentoria.</p>
                <p>A responsabilidade do conteúdo da SESSÃO DE MENTORIA é do MENTOR. O WTF/KNOWBIE não realiza as SESSÕES DE MENTORIA, apenas intermedia o agendamento das mesmas entre o ALUNO e MENTOR. O canal em que a SESSÃO DE MENTORIA ocorrerá é decidido pelo MENTOR. O MENTOR tem como responsabilidade executar a SESSÃO DE MENTORIA no dia e hora combinados. O USUÁRIO não pode postar conteúdo impróprio, conteúdo adulto, ilegal, preconceituoso, que é caracterizado como crime, pedofilia, material com direitos autorais sem permissão do proprietário.</p>
                <Typography.H2 color="white-gray-3">2.4 Serviços e Conteúdo de Terceiros (as).</Typography.H2>
                <p>Os Serviços poderão ser disponibilizados e acessados em conexão com Serviços e conteúdo de terceiros (as) (inclusive publicidade) que a WTF/KNOWBIE não controlará.</p>
                <p>A PARTE RECONHECE QUE TERMOS DE USO E POLÍTICAS DE PRIVACIDADE DIFERENTES PODERÃO SER APLICÁVEIS AO USO DESSES SERVIÇOS E CONTEÚDO DE TERCEIROS (AS).</p>
                <p>A WTF/KNOWBIE NÃO ENDOSSAM ESSES SERVIÇOS E CONTEÚDO DE TERCEIROS (AS) E A WTF/KNOWBIE NÃO SERÃO, EM HIPÓTESE ALGUMA, RESPONSÁVEIS POR NENHUM PRODUTO OU SERVIÇO DESSES (AS) TERCEIROS (AS) FORNECEDORES (AS).</p>
                <p>Além disto, Apple Inc., Google, Inc., Microsoft Corporation ou BlackBerry Limited e/ou suas subsidiárias e afiliadas internacionais serão terceiros (as) beneficiários (as) deste Contrato, caso você acesse os Serviços usando aplicativos desenvolvidos para dispositivos móveis baseados em Apple iOS, Android, Microsoft Windows, ou Blackberry, respectivamente. Esses (as) terceiros (as) beneficiários (as) não são partes deste Contrato e não são responsáveis pela prestação dos Serviços ou por qualquer forma de suporte aos Serviços. Seu acesso aos Serviços usando esses dispositivos está sujeito às condições estabelecidas nos termos de serviços dos respectivos terceiros (as) beneficiários (as).</p>
                <p>A WTF/KNOWBIE poderá utilizar os dados e informações que de alguma forma tenham sido originados ou compartilhados nessa plataforma para outros fins além da natureza do escopo desse software, como por exemplo, na inclusão de mailings, recebimento de ofertas por e-mail etc.</p>
                <Typography.H2 color="white-gray-3">2.5 Titularidade.</Typography.H2>
                <p>Os Serviços e todos os direitos sobre eles são e permanecerão de propriedade da WTF/KNOWBIE ou de propriedade das afiliadas da WTF/KNOWBIE, ou de suas respectivas licenciadoras, conforme o caso. Estes Termos e o uso dos Serviços não outorgam nem lhe conferem qualquer direito: (i) sobre os Serviços, exceto pela licença limitada concedida acima; ou (ii) de usar ou, de qualquer modo, fazer referência a nomes societários, logotipos, nomes de produtos ou de Serviços, marcas comerciais ou marcas de serviço da WTF/KNOWBIE ou de qualquer licenciadora da WTF/KNOWBIE.</p>
                <Typography.H1 color="white-gray-3">3. Uso dos Serviços</Typography.H1>
                <Typography.H2 color="white-gray-3">3.1 Contas de Usuários.</Typography.H2>
                <p>Para utilizar grande parte dos Serviços, a parte deve se registrar e manter uma conta pessoal de usuário de Serviços (“Conta”). Deve ter pelo menos 18 anos para registrar uma Conta. Usuários com idade igual ou superior a 12 anos poderão se registrar e manter uma Conta desde que tenham sido devidamente representados ou tenham obtido o consentimento de seu (s) responsável (is) legal (is), conforme o procedimento para registro aplicável em cada caso.</p>
                <p>O registro de Conta exige que a WTF/KNOWBIE colete determinados dados pessoais, que podem incluir seu nome, endereço, número de telefone celular e data de nascimento, assim como pelo menos um método de pagamento válido (cartão de crédito). Sendo que ao registrar a conta a parte dará o pleno consentimento para utilização dos dados fornecidos.</p>
                <p>A parte/usuário concorda em manter informações corretas, completas e atualizadas em sua Conta. Caso não mantenha as informações corretas, completas e atualizadas em sua Conta, inclusive se o método de pagamento informado for inválido ou expirado, a parte poderá ficar impossibilitado (a) de acessar e usar os Serviços ou a WTF/KNOWBIE poderá resolver estes Termos.</p>
                <p>Você é responsável por todas as atividades realizadas na sua Conta e concorda em manter sempre a segurança e confidencialidade do nome de usuário e senha da sua Conta. A menos que diversamente permitido pela WTF/KNOWBIE por escrito, você poderá manter apenas uma Conta.</p>
                <Typography.H2 color="white-gray-3">3.2 Conduta e Obrigações do Usuário.</Typography.H2>
                <p>A parte não poderá autorizar terceiros (as) a usar sua Conta ou receber serviços de mentoria. Não poderá ceder, nem de qualquer outro modo transferir a Conta a nenhuma outra pessoa ou entidade.</p>
                <p>A parte concorda em cumprir todas as leis aplicáveis quando usar os Serviços e que somente poderá usar os Serviços para finalidades legítimas. Não poderá, quando usar os Serviços, causar transtorno, aborrecimento, inconveniente aos outros players.</p>
                <p>Em determinadas situações, a parte poderá ser solicitado (a) a fornecer comprovante de identidade para acessar ou usar os Serviços fornecidos, podendo ter o acesso ou uso dos Serviços negado caso recuse a fornecer um comprovante de identidade.</p>
                <Typography.H2 color="white-gray-3">3.3 Mensagem de Texto (SMS).</Typography.H2>
                <p>Ao criar uma Conta, e tal como estabelecido na <a target="_blank" href="https://www.wtf.inf.br/politica.php">Política de Privacidade da WTF/KNOWBIE</a>, o usuário concorda que os Serviços poderão lhe enviar mensagens de textos (SMS) como parte das operações comerciais regulares para o uso dos Serviços.</p>
                <Typography.H2 color="white-gray-3">3.4 Conteúdo Fornecido pelo (a) Usuário (a).</Typography.H2>
                <p>A WTF/KNOWBIE poderá, a seu exclusivo critério, permitir que a parte ou qualquer pessoa apresente, carregue, publique ou, de qualquer modo, disponibilize para a WTF/KNOWBIE por meio dos Serviços, conteúdo e informações de texto, áudio ou vídeo, inclusive comentários e feedbacks relacionados aos Serviços, iniciação de solicitação de suporte e registro em concursos e promoções (“Conteúdo de Usuário (a)”).</p>
                <p>Qualquer Conteúdo de Usuário (a) fornecido pela parte permanece de sua propriedade. Contudo, ao fornecer Conteúdo de Usuário(a) para a WTF/KNOWBIE, outorga a WTF/KNOWBIE e suas afiliadas uma licença dos direitos autorais sobre o Conteúdo de Usuário em nível mundial, perpétua, irrevogável, transferível, isenta de royalties, e com direito a sublicenciar, usar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir, executar em público e, de qualquer outro modo, explorar esse Conteúdo de Usuário(a) em todos os formatos e canais de distribuição hoje conhecidos ou desenvolvidos no futuro (inclusive em conexão com os Serviços e com os negócios da WTF/KNOWBIE e em sites e Serviços de terceiros), sem ulterior aviso ou consentimento, e sem necessidade de pagamento ou a qualquer outra pessoa ou entidade.</p>
                <p>A parte declara e garante que: (i) é o(a) único(a) e exclusivo(a) proprietário(a) de todo Conteúdo de Usuário(a) ou tem todos os direitos, licenças, autorizações e permissões necessários para outorgar à WTF/KNOWBIE a licença sobre o Conteúdo de Usuário(a) acima referido; e (ii) nem o Conteúdo de Usuário(a) nem sua apresentação, carregamento, publicação ou outra forma de disponibilização desse Conteúdo de Usuário(a), tampouco o uso do Conteúdo de Usuário(a) pela WTF/KNOWBIE da forma aqui permitida, infringirão, constituirão apropriação indevida nem violarão propriedade intelectual ou direito de propriedade de terceiros(a), nem direitos de publicidade ou privacidade e também não resultarão na violação de qualquer lei ou regulamento aplicável.</p>
                <p>A parte concorda em não fornecer Conteúdo de Usuário (a) que seja difamatório, calunioso, injurioso, violento, obsceno, pornográfico, ilegal ou de qualquer modo ofensivo, conforme apuração da WTF/KNOWBIE a seu critério exclusivo, seja ou não esse material protegido por lei. A WTF/KNOWBIE poderá, mas não está obrigada a, analisar, monitorar ou remover Conteúdo de Usuário (a), a critério exclusivo da WTF/KNOWBIE, a qualquer momento e por qualquer motivo, sem nenhum aviso a você.</p>
                <Typography.H2 color="white-gray-3">3.5 Acesso à Rede e Equipamentos.</Typography.H2>
                <p>A parte é responsável por obter o acesso à rede de dados necessário para usar os Serviços. As taxas e encargos de sua rede de dados e mensagens poderão se aplicar se você acessar ou usar os Serviços de um dispositivo sem fio e você será responsável por essas taxas e encargos. Você é responsável por adquirir e atualizar os equipamentos e dispositivos necessários para acessar e usar os Serviços e Aplicativos e quaisquer de suas atualizações. A WTF/KNOWBIE NÃO GARANTE QUE OS SERVIÇOS, OU QUALQUER PARTE DELES, FUNCIONARÃO EM QUALQUER EQUIPAMENTO OU DISPOSITIVO EM PARTICULAR. Além disso, os Serviços poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da Internet e de comunicações eletrônicas.</p>
                <Typography.H1 color="white-gray-3">4. Pagamento</Typography.H1>
                <p>A parte entende que os serviços que receber de um ALUNO, contratado por meio dos nossos serviços, poderão ser cobrados. Após você ter recebido uma mentoria por meio do WTF/KNOWBIE, facilitaremos o pagamento do respectivo valor cobrado em WTF/KNOWBIE points, agindo na qualidade de agente limitado de cobrança do MENTOR. O pagamento feito dessa maneira será considerado pagamento feito diretamente pelo ALUNO ao MENTOR. O preço pago pelo ALUNO é final e não reembolsável, a menos que diversamente determinado pela WTF/KNOWBIE.</p>
                <p>Os WTF/KNOWBIE POINTS podem ser comprados com cartão de crédito. O ALUNO ou MENTOR só podem converter e sacar os WTF/KNOWBIE POINTS que forem oriundos de SESSÕES DE MENTORIA. Os WTF/KNOWBIE POINTS possuem uma cotação independente, em que sua conversão é apresentada, tanto no momento da compra, quanto do saque. O MENTOR que quiser promover sua sessão pode optar por fazê-lo gastando seu saldo de WTF/KNOWBIE Points. O Custo de uma promoção de sessão pode variar de acordo com os interesses da WTF/KNOWBIE. Porém, após uma promoção ter sido adquirida, o valor permanece. Os WTF/KNOWBIE POINTS disponíveis para saque são somente os que foram adquiridos através de SESSÕES DE MENTORIA. Os WTF/KNOWBIE POINTS comprados podem ser utilizados na compra de SESSÕES DE MENTORIA ou SESSÕES PROMOVIDAS.</p>
                <p>Na relação entre você e a WTF/KNOWBIE, a WTF/KNOWBIE reserva-se o direito de estabelecer, remover e/ou revisar o Preço relativo a todos os serviços ou bens obtidos por meio do uso dos Serviços a qualquer momento, a critério exclusivo da WTF/KNOWBIE.</p>
                <p>A parte poderá optar por cancelar sua solicitação de mentoria sem custos até o Mentor confirmar, depois do Mentor confirmar a sessão, caso em que poderá incidir uma taxa de 10% de cancelamento.</p>
                <Typography.H1 color="white-gray-3">5. Limitação de Responsabilidade.</Typography.H1>
                <p>A WTF/KNOWBIE NÃO SERÁ RESPONSÁVEL POR DANOS INDIRETOS, INCIDENTAIS, ESPECIAIS, PUNITIVOS OU EMERGENTES, INCLUSIVE LUCROS CESSANTES, PERDA DE DADOS, DANOS MORAIS OU PATRIMONIAIS RELACIONADOS, ASSOCIADOS OU DECORRENTES DE QUALQUER USO DOS SERVIÇOS AINDA QUE A WTF/KNOWBIE TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. A WTF/KNOWBIE NÃO SERÁ RESPONSÁVEL POR NENHUM DANO, OBRIGAÇÃO OU PREJUÍZO DECORRENTE DO: (i) SEU USO DOS SERVIÇOS OU SUA INCAPACIDADE DE ACESSAR OU USAR OS SERVIÇOS; OU (ii) QUALQUER OPERAÇÃO OU RELACIONAMENTO ENTRE VOCÊ E QUALQUER OUTRO USUÁRIO, AINDA QUE A WTF/KNOWBIE TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. A WTF/KNOWBIE NÃO SERÁ RESPONSÁVEL POR ATRASOS OU FALHAS DECORRENTES DE CAUSAS FORA DO CONTROLE RAZOÁVEL DA WTF/KNOWBIE E, TAMPOUCO, PELA QUALIDADE E INTEGRIDADE DAS MENTORIAS REALIZADAS POR MENTORES. VOCÊ RECONHECE QUE MENTORES QUE PRESTAREM SERVIÇOS PODERÃO OFERECER SERVIÇOS DE MENTORIA DO TIPO COMPARTILHADA.</p>
                <p>AS LIMITAÇÕES E RECUSA DE GARANTIAS CONTIDAS NESTA CLÁUSULA 5 NÃO POSSUEM O OBJETIVO DE LIMITAR RESPONSABILIDADES OU ALTERAR DIREITOS DE CONSUMIDOR QUE, DE ACORDO COM A LEI APLICÁVEL, NÃO PODEM SER LIMITADOS OU ALTERADOS.</p>
                <Typography.H2 color="white-gray-3">5.1 Indenização.</Typography.H2>
                <p>A parte concorda em indenizar e manter a WTF/KNOWBIE, seus diretores (as), conselheiros (as), empregados (as) e agentes isentos (as) de responsabilidade por todas e quaisquer reclamações, cobranças, prejuízos, responsabilidades e despesas (inclusive honorários advocatícios) decorrentes ou relacionados: (i) ao uso dos Serviços, de serviços obtidos por meio do uso dos Serviços; (ii) descumprimento ou violação de qualquer disposição destes Termos; (iii) o uso, pela WTF/KNOWBIE, do Conteúdo de Usuário(a); ou (iv) violação dos direitos de terceiros.</p>
                <Typography.H1 color="white-gray-3">6. Solução de controvérsias</Typography.H1>
                <p>A WTF/KNOWBIE disponibiliza uma estrutura de atendimento, composta por ferramentas de contato e uma equipe especializada de suporte para o tratamento de reclamações com o objetivo de evitar a judicialização de controvérsias que, eventualmente, possam surgir com o seu uso dos Serviços.</p>
                <p>Diante disso, a parte se compromete a utilizar as ferramentas de suporte disponibilizadas pela WTF/KNOWBIE como primeiro meio para a solução de controvérsias decorrentes do seu uso dos Serviços.</p>
                <p>Você também poderá buscar a solução de controvérsias por meio do serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do Consumidor (SENACON) do Ministério da Justiça e Segurança Pública, que é disponibilizado gratuitamente aos consumidores de todo o país com o objetivo de prevenir e reduzir a quantidade de controvérsias judicializadas.</p>
                <Typography.H1 color="white-gray-3">7. Legislação Aplicável - Jurisdição.</Typography.H1>
                <p>Estes Termos serão regidos e interpretados exclusivamente de acordo com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia que surgir deste contrato ou a ele relacionada, inclusive que diga respeito a sua validade, interpretação ou exequibilidade, será solucionada exclusivamente pelos tribunais do foro de seu domicílio.</p>
                <p>Com o vigor da Lei 13.709/2018 – “LGPD” (Lei Geral de Proteção de Dados), cada PARTE, obriga-se a cumprir com a legislação vigente sobre proteção de dados relativos a uma pessoa física identificada ou identificável, bem como as determinações de órgãos reguladores/fiscalizadores relativos a matéria, além das demais normas e políticas de proteção de dados.</p>
                <p>As PARTES se comprometem a adotar medidas, ferramentas e tecnologias necessárias e empregar os melhores esforços para garantir a segurança dos dados e cumprir com suas obrigações legais e contratuais nesse aspecto.</p>
                <p>As PARTES assumem a obrigação de cumprir com os requisitos das medidas de segurança técnicas e organizacionais para garantir a confidencialidade, sigilo, pseudonimização e a criptografia dos dados pessoais a que tiverem acesso, inclusive no que diz respeito ao seu armazenamento e transmissão.</p>
                <p>Cada PARTE se obriga a utilizar tecnologias visando à proteção das informações em todas as comunicações que trocarem, especialmente nos compartilhamentos de dados qualificados como pessoais.</p>
                <Typography.H1 color="white-gray-3">8. Outras Disposições</Typography.H1>
                <p>Alegações de Violação de Direito Autoral.</p>
                <p>Alegações de violação de direito autoral devem ser encaminhadas ao representante indicado pela WTF/KNOWBIE. Envie um e-mail para <a href="mailto:contato@wtf.inf.br">contato@wtf.inf.br</a>.</p>
                <Typography.H2 color="white-gray-3">8.1 Avisos.</Typography.H2>
                <p>A WTF/KNOWBIE poderá enviar avisos por meio de notificações gerais nos Serviços, correio eletrônico para seu endereço de e-mail em sua Conta, ou por comunicação escrita enviada ao endereço indicado em sua Conta. Você poderá notificar a WTF/KNOWBIE por meio do Aplicativo, comunicação pelo endereço eletrônico help.WTF/KNOWBIE.com ou fazer comunicação escrita para o endereço da WTF/KNOWBIE na Av. Domingos Odália Filho, 301, 15º andar, Sala 1501, CEP 06010-067, Centro, no município de Osasco/SP, Brasil.</p>
                <Typography.H1 color="white-gray-3">9. Disposições Gerais</Typography.H1>
                <p>A não poderá ceder, nem tampouco transferir estes Termos, total ou parcialmente, sem prévia aprovação por escrito da WTF/KNOWBIE. Você concorda que a WTF/KNOWBIE ceda e transfira estes Termos, total ou parcialmente, inclusive: (i) para uma subsidiária ou afiliada; (ii) um adquirente das participações acionárias, negócios ou bens da WTF/KNOWBIE; ou (iii) para um sucessor em razão de qualquer operação societária. Não existe joint-venture, sociedade, emprego ou relação de representação entre você, a WTF/KNOWBIE ou quaisquer MENTOR ou ALUNO como resultado do contrato entre você e a WTF/KNOWBIE ou pelo uso dos Serviços.</p>
                <p>Caso qualquer disposição destes Termos seja tida como ilegal, inválida ou inexequível total ou parcialmente, por qualquer legislação, essa disposição ou parte dela será, naquela medida, considerada como não existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes Termos não serão afetadas. Nesse caso, as partes substituirão a disposição ilegal, inválida ou inexequível, ou parte dela, por outra que seja legal, válida e exequível e que, na máxima medida possível, tenha efeito similar à disposição tida como ilegal, inválida ou inexequível para fins de conteúdo e finalidade dos presentes Termos. Estes Termos constituem a totalidade do acordo e entendimento das partes sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos anteriores sobre este assunto. Nestes Termos, as palavras “inclusive” e “inclui” significam “incluindo, sem limitação”.</p>
            </div>
        </div>
    )
}
