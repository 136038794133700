import RedefinePassword from './RedefinePassword'
import Home from './Home'
import TermsAndConditions from './TermsAndConditions'
import Support from './Support'
import PrivacyPolicy from './PrivacyPolicy'



export default {
    RedefinePassword,
    Home,
    TermsAndConditions,
    Support,
    PrivacyPolicy
}