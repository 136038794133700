//GLOBAL - components from npm
import React from "react";
import { Routes as Switch, Route } from "react-router-dom";

//STYLES

//SERVICES - api, conectors...


//COMPONENTS
import Pages from "../pages";

//GLOBAL STATE - redux, env...


//ASSETS - icons, images...

export default function Routes() {
  //GENERAL


  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS
  

  return (
    <Switch>
      <Route
        path="/"
        element={
            <Pages.Home/>
        }
      />
      <Route
        path="/app/redefinir-senha/:token"
        element={
            <Pages.RedefinePassword />
        }
      />
      <Route
        path="/app/termos-condicoes"
        element={
            <Pages.TermsAndConditions />
        }
      />
      <Route
        path="/app/politica-de-privacidade"
        element={
            <Pages.PrivacyPolicy />
        }
      />
      <Route
        path="/app/suporte"
        element={
            <Pages.Support />
        }
      />
    </Switch>
  );
}
