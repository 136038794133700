//GLOBAL - components from npm
import React from 'react';

//STYLES
import './h-2.scss'

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function H2({ children, weight, className, color }) {


    return (
        <div className={`h-2 h-2--${weight} ${className ? className : ''} h-2--${color}`}>
            {children}
        </div>
    )
}
