//GLOBAL - components from npm
import React, {useEffect} from 'react';

//STYLES
import './privacy-policy.scss'

//COMPONENTS
import { Typography } from "../../components/General";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function PrivacyPolicy() {
    //GENERAL
    
    
    //STATES
    
    
    //REDUX - Selectors
    
    
    //FUNCTIONS
    
    //USE EFFECTS
    
    return (
        <div className="privacy-policy">
            <div className="privacy-policy-content">
                <div className="privacy-policy-content__title"><Typography.H1 color="white-gray-3">Política de Privacidade e Proteção de dados pessoais</Typography.H1></div>
                <Typography.H1 color="white-gray-3">1. SEUS DADOS SÃO SEUS</Typography.H1>
                <p>Sabemos que se preocupa com os seus dados pessoais e sobre o modo como são utilizados, e queremos que confie no <b>KNOWBIE e na WELCOME THE FUTURE TECNOLOGIA LTDA</b> para utilizar os seus dados pessoais com cuidado. Esta Política de Privacidade irá ajudá-lo a compreender que dados pessoais coletados, porque os coletamos e aquilo que fazemos com eles.</p>
                <p><b>O KNOWBIE e na WELCOME THE FUTURE TECNOLOGIA LTDA</b> são empresas do grupo <b>DRUMMOND PARTICIPAÇÕES LTDA.</b></p>
                <p>Ao ler nossa Política de Privacidade, por favor, tenham em conta que o mesmo se aplica à <b>DRUMMOND PARTICIPAÇÕES LTDA,</b> inscrita no CNPJ/MF sob o nº 29.316.228/0001-77 e demais empresas afiliadas e coligadas do grupo <b>DRUMMOND PARTICIPAÇÕES LTDA</b> no Brasil. Por favor dedique alguns instantes a conhecer as nossas práticas de privacidade e, caso tenha quaisquer questões, contate-nos através de e-mail (<a href="mailto:privacidade@drummondpar.com">privacidade@drummondpar.com</a>), ou submeta-nos um pedido através do formulário “Contate-nos” disponível no nosso website. Tentamos manter nossa Política tão simples quanto possível, mas caso não esteja familiarizado com os termos utilizados, tais como cookies, endereços IP, e browsers, então leia primeiro e entenda estes conceitos-chaves. Você tem o direito de se opor ao tratamento dos seus dados pessoais, incluindo a utilização dos seus dados pessoais para marketing direto. Veja aqui quais são os seus direitos e como os pode exercer.</p>
                <Typography.H1 color="white-gray-3">2. QUEM OS COLETA?</Typography.H1>
                <p>Quaisquer dados pessoais fornecidos ou recolhidos pela <b>DRUMMOND PARTICIPAÇÕES LTDA</b> através de outras tecnologias de monitorização são tratados pela <b>DRUMMOND PARTICIPAÇÕES LTDA</b> no Brasil inscrita no CNPJ/MF sob o n° 29.316.228/0001-77, com sede na Av.</p>
                <p>Tancredo Neves, Edif. Garcia D’Ávilla, número 1.543, 7º Andar, Caminho das Árvores, Cidade Salvador-BA, CEP: 41.820-021 e demais empresas afiliadas e coligadas do grupo <b>DRUMMOND PARTICIPAÇÕES LTDA</b> no Brasil. Esta política de Privacidade também se aplica ao conteúdo de marketing da <b>DRUMMOND PARTICIPAÇÕES LTDA</b>, incluindo ofertas e publicidade a produtos e serviços <b>DRUMMOND PARTICIPAÇÕES LTDA</b>, que nós (ou um fornecedor de serviços atuando em nosso nome) lhe apresentamos em websites, plataformas e aplicações de terceiros com base na sua informação de utilização do nosso site. Estes websites de terceiros terão geralmente as suas próprias <b>Políticas e Termos e Condições de Privacidade.</b> Recomendamos que os leia antes de utilizar esses websites.</p>
                <Typography.H1 color="white-gray-3">3. QUE DADOS PESSOAIS SÃO COLETADOS?</Typography.H1>
                <p>Dados pessoais significa qualquer informação relativa a uma pessoa singular identificada ou identificável (“titular dos dados”). É considerada identificável uma pessoa singular que possa ser identificada, direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrônica ou genética, mental, econômica, cultural ou social dessa pessoa singular. Você não é obrigado a fornecer à <b>DRUMMOND PARTICIPAÇÕES LTDA</b> os dados pessoais que esta solicita, mas caso escolha não o fazer, poderemos não conseguir oferecer-lhe os nossos produtos e serviços, ou um serviço de alta qualidade ou responder a alguma questão que possa ter.</p>
                <Typography.H1 color="white-gray-3">4. MODO QUE UTILIZAMOS PARA RECOLHER OS SEUS DADOS PESSOAIS</Typography.H1>
                <p>Poderemos recolher os seus dados pessoais de várias fontes, a saber:</p>
                <p><b>1-</b> Dados pessoais diretamente fornecidos por você.</p>
                <p><b>2-</b> Dados pessoais que você nos fornece ao subscrever um newsletter publicitário, ao preencher um formulário ou ao registrar uma conta para adquirir alguns de nossos produtos.</p>
                <p><b>2.1-</b> Nestas ações poderemos solicitar alguns dos seus dados pessoais, tais como o seu nome, data de nascimento, endereço residencial, endereço eletrônico, número de telefone, CPF;</p>
                <p><b>3-</b> Dados pessoais que coletamos automaticamente;</p>
                <p><b>3.1-</b> Coletamos e guardamos também certos tipos de dados pessoais sempre que você interage conosco online. Por exemplo, utilizamos cookies e tecnologias de monitorização (para saber mais, consulte os nossos conceitos-chave de privacidade bem como a nosso Política de Cookies) para obter dados pessoais sempre que o seu navegador de internet acessa os nossos websites ou publicidades e outros conteúdos oferecidos pela <b>DRUMMOND PARTICIPAÇÕES LTDA</b> ou em outros websites contratados por esta. Exemplos dos tipos de dados pessoais que coletamos incluem endereço IP (para saber mais, consulte os nossos conceitos-chave de privacidade), ID do Dispositivo, dados de localização, informações sobre o computador e a ligação tais como o tipo e versão de browser, definições de fuso horário, tipos e versões de plug-ins utilizadas pelo browser, sistema operativo, e histórico que a <b>DRUMMOND PARTICIPAÇÕES LTDA</b> por vezes agrega com informação similar de outros consumidores. Durante parte da sua navegaçãono website <b>DRUMMOND PARTICIPAÇÕES LTDA</b> poderemos também utilizar ferramentas de software para medir e recolher informações sobre a sua sessão, incluindo tempos de resposta das páginas, erros de download, duração da visita a certas páginas, informação relativa à sua interação com elas, e os métodos utilizados para sair das mesmas. Poderemos também recolher informação técnica para nos ajudar a identificar o seu dispositivo para fins de prevenção à fraude e de diagnóstico;</p>
                <p><b>4-</b> Dados pessoais que coletamos de outras fontes;</p>
                <p><b>4.1-</b> Coletamos de fontes de terceiros com quem tenhamos parcerias de confiança e em plataformas onde operamos contas <b>DRUMMOND PARTICIPAÇÕES LTDA.</b> Por exemplo, quando você usa a funcionalidade de “like” no Facebook ou a funcionalidade “+1” no Google+. Além disso, recebemos informação sobre a sua interação, bem como a de outros utilizadores, com a nossa publicidade para aferir se esta é relevante e bem-sucedida. Coletamos também informação sobre você e as suas atividades nestes terceiros, nos casos em que ofereçamos serviços ou produtos em conjunto, ou de fornecedores de dados enriquecidos externos que poderão oferecer à <b>DRUMMOND PARTICIPAÇÕES LTDA</b> uma visão diferente sobre os dados pessoais que detemos.</p>
                <Typography.H1 color="white-gray-3">5. QUANDO E COMO COLETAMOS “CATEGORIAS ESPECIAIS DE DADOS PESSOAIS”</Typography.H1>
                <p>Certas categorias de dados pessoais, tais como os que revelem à origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical, os dados genéticos, os dados biométricos para identificar uma pessoa de forma inequívoca, os dados relativos à saúde ou relativos à vida sexual ou orientação sexual são classificadas como “categorias especiais de dados pessoais” e beneficiam de proteção adicional sob a legislação Europeia de proteção de dados. Limitamos tanto quanto possível as circunstâncias em que coletamos e tratamos estas categorias especiais de dados. A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> por vezes recolhe dados relativos à sua saúde, tais como alergias, estado da gravidez ou o tipo de pele para lhe apresentar publicidade e promoções relevantes. A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> apenas recolhe e utiliza estes dados pessoais em situações onde nos tenha dado o seu consentimento para o fazer. Em alguns casos, poderá ter-nos solicitado serviços ou produtos que não envolvem diretamente a recolha de quaisquer categorias especiais de dados, mas que poderão insinuar ou sugerir a sua religião, estado de saúde ou outras categorias especiais de dados.</p>
                <Typography.H1 color="white-gray-3">6. COMO PROTEGEMOS A PRIVACIDADE DAS CRIANÇAS?</Typography.H1>
                <p>Compreendemos a importância de tomar precauções adicionais para proteger a privacidade e segurança das crianças que utilizam os produtos e serviços <b>DRUMMOND PARTICIPAÇÕES LTDA</b>. A maioria dos websites <b>DRUMMOND PARTICIPAÇÕES LTDA</b> são desenhados e destinados ao uso por adultos. Nos casos em que algum dos nossos websites seja destinado a ser utilizado por uma audiência mais jovem, obter-se-á o consentimento da pessoa com responsabilidade parental nos casos em que tal se mostre necessário, no estrito cumprimento pelas leis e regulamentos aplicáveis (“sendo que, nos termos do LGPD, sempre a criança tenha menos de 16 anos, o tratamento só lícito se na medida em que o consentimento seja dado ou autorizado pelos titulares das responsabilidades parentais”.). Se for uma criança com menos de 16 anos, deverá rever os termos desta Política de Privacidade com os titulares das responsabilidades parentais para assegurar que os compreende e aceita. Caso seja do nosso conhecimento que coletamos dados pessoais de uma criança sem o consentimento dos titulares das responsabilidades parentais em casos onde este deveria ter sido obtido, iremos apagar esses dados pessoais assim que seja possível. Por vezes utilizamos os seus dados pessoais para efetuar verificações de idade e fazer cumprir quaisquer restrições etárias.</p>
                <Typography.H1 color="white-gray-3">7. PARA QUE FINALIDADE UTILIZAMOS OS SEUS DADOS?</Typography.H1>
                <p>Nós coletamos, tratamos e divulgamos os seus dados pessoais apenas para finalidades específicas e limitadas. Por exemplo, para processar os seus pagamentos, para avaliar e lidar com quaisquer reclamações, para desenvolver e melhorar os nossos produtos, serviços, métodos de comunicação e a funcionalidade dos nossos websites, para lhe oferecer produtos e comunicações personalizadas, assim como publicidade direcionada e recomendações de produtos. Criamos também perfis através da análise da informação sobre a sua navegação online, as pesquisas e hábitos de compra, e as suas interações com as comunicações das nossas marcas, através da construção de segmentos (criação de grupos que partilham características comuns) e colocação dos seus dados pessoais em um ou mais desses segmentos. Além disso, a <b>DRUMMOND PARTICIPAÇÕES LTDA</b> processa os seus dados pessoais utilizando também meios automatizados. Uma decisão automatizada é uma decisão tomada em exclusivo por meios automáticos, onde nenhum humano está envolvido no processo de decisão que envolve os seus dados pessoais. Outros exemplos de finalidades de tratamento dos seus dados pessoais são os que seguem:</p>
                <p><b>1-</b> Para lhe comunicar informação e para gerir o seu registro e/ou subscrição o nosso newsletter ou outras comunicações;</p>
                <p><b>2-</b> Para autenticar a identidade de indivíduos que nos contatam por telefone, meios eletrônicos ou outros;</p>
                <p><b>3-</b> Para fins de formação e garantia de qualidade;</p>
                <p><b>4-</b> Para compreender e avaliar os interesses, desejos, e necessidades em mudança dos consumidores, para melhorar o nosso website, os nossos produtos e serviços atuais, e/ou desenvolver novos produtos e serviços;</p>
                <p><b>5-</b> Para lhe fornecer produtos e comunicações personalizados, assim como publicidade direcionada e recomendações de produtos.</p>
                <p>Sempre que coletarmos e utilizarmos os seus dados pessoais para as finalidades acima mencionadas ou para outros fins, iremos informá-lo previamente ou no momento da coleta. Sempre que necessário, iremos pedir o seu consentimento para processar os seus dados pessoais. Quando você tiver fornecido o seu consentimento para as atividades de tratamento, terá também o direito de retirar o seu consentimento em qualquer momento. Muitos são os casos em que existe um legítimo interesse para tratar os seus dados pessoais.</p>
                <p>Um legítimo interesse pode existir, por exemplo, quando você subscreve um programa de fidelização de uma das nossas marcas e utilizamos os dados pessoais coletados para conduzir análises de dados para melhorar os nossos produtos e serviços. Outro exemplo de legítimo interesse: para auxiliar a execução de um contrato, ou para otimizar um serviço. Esta base legal será apenas utilizada onde não exista um modo menos intrusivo de tratar os seus dados pessoais. Podemos assegurar que se o legítimo interesse for utilizado como motivo para tratar os seus dados pessoais, iremos manter um registro dessa ação e terá o direito de solicitar essa informação. Tratamos também os seus dados pessoais para executar um contrato no qual é ou venha a ser parte.</p>
                <p>Por exemplo, necessitamos de tratar os seus dados pessoais para entregar um produto ou um serviço que tenha adquirido. Tratamos ainda os seus dados pessoais quando necessário ao cumprimento de uma obrigação jurídica (por exemplo, uma obrigação fiscal ou de segurança social) que nos obriga a processar os seus dados. A título de exemplo, será o caso quando estejamos perante uma ordem judicial que exija o tratamento dos seus dados pessoais para uma finalidade específica, ou quando sejamos obrigados a processar dados pessoais para denunciar transações suspeitas.</p>
                <Typography.H1 color="white-gray-3">8. DEFINIÇÕES DE PERFIS</Typography.H1>
                <p>A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> utiliza os seus dados pessoais para construir perfis. Criamos perfis através da análise da informação sobre a sua navegação online, as pesquisas e hábitos de aquisição de serviços, e as suas interações com as comunicações das nossas marcas, através da construção de segmentos (criação de grupos que partilham características comuns) e colocação dos seus dados pessoais em um ou mais segmentos.</p>
                <p>Estes segmentos são utilizados pela <b>DRUMMOND PARTICIPAÇÕES LTDA</b> para personalizar o website e as nossas comunicações à sua medida (tais como mostrar-lhe conteúdo relevante quando visita o nosso site, ou em um newsletter que lhe seja dirigida), e para lhe exibir ofertas e publicidade relevantes de marcas <b>DRUMMOND PARTICIPAÇÕES LTDA</b> e em websites de terceiros. Os segmentos podem ser também utilizados para campanhas de terceiros no site da <b>DRUMMOND PARTICIPAÇÕES LTDA.</b> A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> cria perfis com os seus dados desde que nos tenha dado o seu consentimento para o fazermos.</p>
                <p>A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> recolhe dados para construir seu perfil, com o seu consentimento:</p>
                <p><b>1-</b> No nosso website, de acordo com aquilo que você visualiza e o modo como interage com o nosso conteúdo;</p>
                <p><b>2-</b> Na nossa publicidade digital que apresentamos em plataformas sociais e outros websites; e</p>
                <p><b>3-</b> Em formulários que preencha online e nos envie com os seus interesses;</p>
                <p><b>4-</b> Monitoramos também os produtos que você “clica” em nossos anúncios publicitários e avança até adquirir um produto de um dos nossos parceiros.</p>
                <p>Caso você tenha solicitado um de nosso contato por e-mails ou comunicações SMS da nossa parte, é efetuado um registo quando os abre, lê, ou clica no seu conteúdo para ver aquilo que lhe interessa, por forma a que lhe possamos oferecer mais conteúdo que pensamos ser do seu agrado. Utilizamos estes dados para determinar aquilo que você gosta e o que não gosta. Com base nessas informações de perfil, poderemos também oferecer-lhe publicidade que pensamos que irá gostar e desejar ver, uma vez que visualiza os conteúdos que disponibilizamos nos nossos sites ou através da nossa rede de editores com os quais atuamos. Por vezes, com o seu consentimento, poderemos utilizar a sua localização atual para lhe oferecer publicidade referente a promoções ou eventos locais que pensamos serem do seu interesse. Poderemos também utilizar informação por você fornecida a terceiros selecionados e cuja partilha tenha sido consentida para identificar pessoas que pensamos terem interesses semelhantes aos seus e que acreditamos estarem interessados em publicidade similar.</p>
                <Typography.H1 color="white-gray-3">9. TRANSFERÊNCIA INTERNACIONAIS DE DADOS</Typography.H1>
                <p>A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> partilha dados pessoais dentro do Grupo <b>DRUMMOND PARTICIPAÇÕES LTDA</b> ou com terceiros para os propósitos descritos neste Termo de Privacidade. A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> apenas enviará dados pessoais coletados no interior do Espaço Econômico Europeu (EEE) a países estrangeiros nas seguintes circunstâncias:</p>
                <p><b>1-</b> Para seguir as suas instruções;</p>
                <p><b>2-</b> Para cumprir com um dever legal; ou</p>
                <p><b>3-</b> Para trabalhar com agentes e consultores que nos ajudam a administrar os nossos negócios e serviços.</p>
                <p>Caso efetue uma transferência de dados pessoais para o exterior do EEE, a <b>DRUMMOND PARTICIPAÇÕES LTDA</b> assegurará que estes são protegidos do mesmo modo como se estivessem a ser utilizados na EEE. Para o efeito, utilizaremos uma das seguintes salvaguardas:</p>
                <p><b>1-</b> Transferência para um país não pertencente à EEE cuja legislação de privacidade assegura um nível adequado de proteção de dados pessoais semelhante aos existentes na EEE;</p>
                <p><b>2-</b> Implementação de um contrato com a entidade estrangeira que a obrigue a proteger os dados pessoais de acordo com os mesmos padrões utilizados na EEE; ou</p>
                <p><b>3-</b> Transferência de dados pessoais para organizações com acordos específicos de transferência de dados internacionais com a União Europeia (a PrivacyShield, uma estrutura que define padrões de privacidade para dados partilhados entre os Estados Unidos e os países Europeus ou enquadramentos semelhantes).</p>
                <Typography.H1 color="white-gray-3">10. COMO PROTEGEMOS OS SEUS DADOS PESSOAIS?</Typography.H1>
                <p>A <b>DRUMMOND PARTICIPAÇÕES LTDA</b> leva muito a sério a segurança dos seus dados pessoais. Envidamos todos os esforços para proteger os seus dados pessoais de uso indevido, interferência, perda, acesso não autorizado, modificação ou divulgação. As nossas medidas incluem a implementação de controles de acesso apropriados, investindo na mais recente capacidade de segurança da informação para proteger os nossos ambientes informáticos, e assegurando a encriptar, pseudonimizamos e anonimizamos dados pessoais sempre que possível. O acesso aos seus dados pessoais é apenas permitido entre os nossos funcionários e agentes em função da necessidade de conhecimento e sujeito a rigorosas obrigações contratuais de confidencialidade quando processados por terceiros.</p>
                <Typography.H1 color="white-gray-3">11. ATÉ QUANDO TRATAMOS OS SEUS DADOS PESSOAIS?</Typography.H1>
                <p>Manteremos os seus dados pessoais durante tanto tempo quanto deles necessitarmos para a finalidade para a qual são tratados. Por exemplo, quando você efetuar uma aquisição de nossos produtos ou serviços, manteremos os dados relativos à sua adesão, para que possamos cumprir o contrato específico que celebrou, após o que manteremos os seus dados pessoais durante um período que nos permita lidar ou responder a quaisquer queixas, questões ou preocupações relativas à adesão. Guardamos os dados identificáveis que coletamos diretamente para fins específicos, durante o mínimo tempo possível, após o qual tomaremos medidas para os apagar definitivamente.</p>
                <p>Reveremos ativamente os dados pessoais que detemos e apagaremos de modo seguro, ou em alguns casos anonimizaremos, quando cessar a obrigação legal, comercial ou do consumidor para que sejam mantidos.</p>
                <Typography.H1 color="white-gray-3">12. QUAIS SÃO OS SEUS DIREITOS?</Typography.H1>
                <p>Você é titular de um conjunto de direitos em relação aos seus dados pessoais e ao modo como são tratados. Você poderá exercer esses direitos em qualquer momento. Fornecemos abaixo um resumo geral desses direitos, juntamente com aquilo que implicam para si. Você pode exercer os seus direitos enviando-nos um e-mail (<a href="mailto:privacidade@drummondpar.com">privacidade@drummondpar.com</a>) ou submetendo um pedido através do nosso formulário “Contate-nos” disponível nos nossos websites.</p>
                <p><b>1-</b> O direito à informação. O titular dos dados tem direito a que lhe seja concedida informação concisa, transparente, compreensível e de fácil acesso, utilizando uma linguagem clara e simples, sobre o modo como utilizamos os seus dados pessoais e sobre os seus direitos. Nestes termos, apresentamos essa informação nesta Política de Privacidade;</p>
                <p><b>2-</b> O direito de acesso e retificação. O titular dos dados tem direito a acessar os seus dados pessoais, bem como a retificar os dados pessoais inexatos que lhe digam respeito, ou a completá-los caso os mesmos se mostrem incompletos. Compreendemos a importância destes direitos e caso os deseje exercer, por favor contate-nos;</p>
                <p><b>3-</b> O direito à portabilidade dos dados. Os dados pessoais que nos facilitou podem ser portáveis. Isto significa que poderão ser transferidos, copiados ou transmitidos eletronicamente em certas circunstâncias;</p>
                <p><b>4-</b> O direito ao apagamento dos dados. Em determinadas condições, o titular dos dados tem o direito de obter do responsável pelo tratamento o apagamento dos seus dados pessoais, sem demora justificada. Caso deseje apagar os seus dados pessoais, por favor informe-nos e tomaremos as medidas razoáveis para responder ao seu pedido de acordo com os requisitos legais. Caso os dados pessoais que coletamos deixem de ser necessários para qualquer fim e não formos por lei obrigados a mantê-los, desenvolveremos os nossos melhores esforços no sentido de os eliminar, destruir ou anonimizar definitivamente;</p>
                <p><b>5-</b> O direito à limitação do tratamento. Em determinadas condições, o titular dos dados tem o direito de limitar o tratamento dos seus dados pessoais;</p>
                <p><b>6-</b> O direito de oposição. O titular dos dados tem o direito de se opor a qualquer momento, por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, incluindo a definição de perfis nos termos da Lei Geral de Proteção de Dados. Nomeadamente, quando os dados pessoais forem tratados para efeitos de comercialização direta, o titular dos dados tem o direito de se opor a qualquer momento ao tratamento dos dados pessoais que lhe digam respeito para os efeitos da referida comercialização (ex. receber e-mails de notificação nossos ou ser contatado com potenciais oportunidades variadas);</p>
                <p><b>7-</b> O direito a apresentar uma queixa a Autoridade Nacional de Proteção de Dados. O titular tem o direito de apresentar reclamação a Autoridade Nacional de Proteção de Dados sobre o modo como tratamos os seus dados pessoais;</p>
                <p><b>8-</b> O direito a retirar o consentimento. Caso tenha dado o seu consentimento à nossa utilização dos seus dados pessoais (ex., dependemos do seu consentimento como base legal para tratar os seus dados pessoais), o titular tem o direito de retirar o seu consentimento em qualquer momento (a retirada do consentimento não compromete a licitude do tratamento efetuado com base no consentimento previamente dado), comunicando-nos a sua intenção através dos contatos fornecidos abaixo;</p>
                <p><b>9-</b> Direitos relacionados com a tomada de decisões automatizadas. O titular dos dados tem o direito de não ser sujeito a decisões unicamente baseadas em processos automatizados que produzam efeitos legais ou outros efeitos significativos.</p>
                <p><b>10-</b> Em particular, tem o direito de:</p>
                <p><b>10.1-</b> Obter intervenção humana;</p>
                <p><b>10.2-</b> Expressar o seu ponto de vista;</p>
                <p><b>10.3-</b> Obter uma justificação da decisão obtida após uma avaliação; e</p>
                <p><b>10.4-</b> Contestar tal decisão.</p>
                <p>Qualquer informação complementar ou aconselhamento sobre os seus direitos podem ser obtidos através da entidade reguladora da matéria de proteção de dados.</p>
                <Typography.H1 color="white-gray-3">13. COMO CONTATAR A DRUMMOND PARTICIPAÇÕES LTDA?</Typography.H1>
                <p>O Encarregado da Proteção de Dados <b>DPO: Hudson Barbosa</b> nomeado pelo grupo <b>DRUMMOND PARTICIPAÇÕES LTDA</b> pode ser contatado no endereço da DRUMMOND PARTICIPAÇÕES LTDA Av. Tancredo Neves, Edf. Garcia D’Ávilla, número 1.543, 7º Andar, Caminho das Árvores, Cidade Salvador-BA, CEP: 41.820-021 ou por e-mail (<a href="mailto:privacidade@drummondpar.com">privacidade@drummondpar.com</a>). Caso tenha quaisquer questões ou preocupações sobre o Termo de Privacidade <b>DRUMMOND PARTICIPAÇÕES LTDA</b> ou o tratamento de dados ou deseje apresentar uma reclamação sobre uma possível violação das leis de privacidade, por favor contate-nos através de e-mail (<a href="mailto:privacidade@drummondpar.com">privacidade@drummondpar.com</a>) ou submetendo um pedido através do nosso formulário “<a target="_blank" href="https://sistemas.tel.inf.br/exerca_seus_direitos/?url=https://www.drummondpar.com">exerça os seus direitos</a>” disponível nos nossos websites. Quando é recebida uma dúvida de privacidade ou pedido de acesso temos uma equipe dedicada que efetua a triagem dos contatos e procura responder à questão ou dúvida específica levantada. Caso a sua questão tenha uma natureza mais específica, iremos procurar mais informação para lhe dar. Todos estes contatos relevantes receberão uma resposta. Exerça os seus direitos <a target="_blank" href="https://sistemas.tel.inf.br/exerca_seus_direitos/?url=https://www.wtfinf.br">clicando aqui</a>.</p>
            </div>
        </div>
    )
}
