//GLOBAL - components from npm
import React, { useState } from 'react';
import InputMask from "react-input-mask";

//STYLES
import './input.scss'

//COMPONENTS
import {Typography} from '../../General';


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconEye } from '../../../assets/icons/Eye.svg'
import { ReactComponent as IconEyeSlash } from '../../../assets/icons/EyeSlash.svg'


export default function Input({name, id, type = 'text', placeholder, onChange, error, rightIcon, leftIcon, typeIcon, value='', className, onBlur}) {
    //GENERAL
    
    //STATES
    const [inputOpen, setInputOpen] = useState(false);

    
    //REDUX - Selectors
    
    
    //FUNCTIONS
    
    
    //USE EFFECTS
    
    switch (type) {
        case 'text':
            return (
                <div className={`${className} input-container`}>
                    <input className={`input ${leftIcon ? 'input--left': ''} ${rightIcon ? 'input--right': ''}`} autoComplete='on' onBlur={(e) => onBlur(e.target.value)} value={value} name={name ? name : ""} type={type} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} id={id}/>
                    {leftIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--left input-icon--${typeIcon}`}>{leftIcon}</label>}
                    {rightIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--right input-icon--${typeIcon}`}>{rightIcon}</label>}
                    {error && <Typography.Text color="negative-red">{error}</Typography.Text>}
                </div>
            )
        case 'password':
            return (
                <div className={`${className} input-container`}>
                    <input className={`input ${leftIcon ? 'input--left': ''} input--right`} onBlur={(e) => onBlur(e.target.value)} autoComplete='off' value={value} name={name ? name : ""} type={inputOpen ? 'text' : 'password'} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} id={id}/>
                    {leftIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--left input-icon--${typeIcon}`}>{leftIcon}</label>}
                    {inputOpen ? 
                        <div onClick={() => setInputOpen(false)} className={`input-icon input-icon--right input-icon--eye`}><IconEye/></div> 
                    : 
                        <div onClick={() => setInputOpen(true)} className={`input-icon input-icon--right input-icon--eye`}><IconEyeSlash/></div>
                    }
                    {error && <Typography.Text color="negative-red">{error}</Typography.Text>}
                </div>
            )
        case 'textarea':
            return (
                <div className={`${className} input-container-textarea`}>
                    <textarea className={`input-text-area `} value={value} onBlur={(e) => onBlur(e.target.value)} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
                    {error && <Typography.Text color="negative-red">{error}</Typography.Text>}
                </div>
            )
        case 'telephone':
            return (
                <div className={`${className} input-container`}>
                    <InputMask className={`input ${leftIcon ? 'input--left': ''}`} onBlur={(e) => onBlur(e.target.value)} mask="(99) 9 9999-9999" placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
                    {leftIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--left input-icon--${typeIcon}`}>{leftIcon}</label>}
                    {error && <Typography.Text color="negative-red">{error}</Typography.Text>}
                </div>
            )
        default :
            return (
                <div className={`${className} input-container`}>
                    <input className={`input ${leftIcon ? 'input--left': ''} ${rightIcon ? 'input--right': ''}`} onBlur={(e) => onBlur(e.target.value)} name={name ? name : ""} value={value} autoComplete='on' type={type} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} id={id}/>
                    {leftIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--left input-icon--${typeIcon}`}>{leftIcon}</label>}
                    {rightIcon && <label htmlFor={id ? id : ''} className={`input-icon input-icon--right input-icon--${typeIcon}`}>{rightIcon}</label>}
                    {error && <Typography.Text color="negative-red">{error}</Typography.Text>}
                </div>
            )
    }
    
}
