//GLOBAL - components from npm
import React, { useState } from "react";
import validator from "validator";

//STYLES
import "./support.scss";

//COMPONENTS
import { Typography, Button } from "../../components/General";
import { Input } from "../../components/DataEntry";
import { Loading } from "../../components/Feedback";

//SERVICES - api, conectors...
import * as APISupport from "../../services/api/support";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconLock } from "../../assets/icons/Lock.svg";
import { ReactComponent as IconLogo } from "../../assets/icons/Logo.svg";
import { ReactComponent as IconUser } from "../../assets/icons/User.svg";
import { ReactComponent as IconEmail } from "../../assets/icons/Email.svg";
import { ReactComponent as IconPhone } from "../../assets/icons/Phone.svg";
import check from "../../assets/images/check.png";

export default function Support() {
  //GENERAL

  //STATES
  const [form, setForm] = useState({ name: "", email: "", phone: "", message: "" });
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const handleCheckForm = () => {
    const tel = form.phone.replaceAll("_", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");
  
    const newError = {};

    if (tel.length > 0 && tel.length < 11) {
      newError.phone = true;
    }

    if (!form.name || !form.name.trim()) {
      newError.name = true;
    }

    if (!form.email || !validator.isEmail(form.email)) {
      newError.email = true;
    }

    if (!form.message || !form.message.trim()) {
      newError.message = true;
    }

    if (newError.name || newError.email || newError.message || newError.phone) {
      return true;
    }
  }

  const checkInput = (value, type) => {
    const newError = {};
    if(type === 'name') {
      if (!value || !value.trim()) {
        newError.name = "Digite o seu nome";
        setError({...error, name: newError.name});
      }
    }
    if(type === 'email') {
      if (!value || !validator.isEmail(value)) {
        newError.email = "Digite um e-mail válido";
        setError({...error, email: newError.email});
      }
    }
    if(type === 'phone') {
      const tel = form.phone.replaceAll("_", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");
      if (tel.length > 0 && tel.length < 11) {
        newError.phone = "Digite um número de telefone válido";
        setError({...error, phone: newError.phone});
      }
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const response = await APISupport.postSupport(form);

    if (response.status === 200) {
      return setSuccess(true);
    } else {
      setError({ text: "Algo deu errado no envio da mensagem." });
      setLoading(false);
    }
  };

  //USE EFFECTS


  return (
    <div className="support">
      <div className="support-container">
        <div className="support-container-header">
          <div className="support-container-header__icon">
            <IconLock />
          </div>
          <IconLogo />
        </div>
        {success ? (
          <div className="support-success">
            <Typography.H1 color="white-gray-3">Mensagem enviada!</Typography.H1>
            <img alt="check" src={check} />
          </div>
        ) : (
          <>
            <Typography.H1 color="white-gray-3">Entrar em contato</Typography.H1>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Input
                type="text"
                error={error?.name}
                placeholder="Nome"
                typeIcon="white-gray-6"
                onBlur={(value) => {checkInput(value, 'name')}}
                leftIcon={<IconUser />}
                className={error?.name ? "input-error" : ''}
                value={form.name}
                onChange={(value) => {
                  setForm({ ...form, name: value });
                  setError({ ...error, name: null });
                }}
              />
              <Input
                type="text"
                error={error?.email}
                typeIcon="white-gray-6"
                className={error?.email ? "input-error" : ''}
                leftIcon={<IconEmail />}
                placeholder="Email"
                value={form.email}
                onBlur={(value) => {checkInput(value, 'email')}}
                onChange={(value) => {
                  setForm({ ...form, email: value });
                  setError({ ...error, email: null });
                }}
              />
              <Input
                type="telephone"
                typeIcon="white-gray-6"
                className={error?.phone ? "input-error" : ''}
                error={error?.phone}
                leftIcon={<IconPhone />}
                placeholder="Telefone (opcional)"
                value={form.phone}
                onBlur={(value) => {checkInput(value, 'phone')}}
                onChange={(value) => {
                  setForm({ ...form, phone: value });
                  setError({ ...error, phone: null });
                }}
              />
              <Input
                type="textarea"
                error={error?.message}
                className={error?.message ? "input-error" : ''}
                placeholder="Digite sua mensagem..."
                value={form.message}
                onBlur={(value) => {checkInput(value, 'message')}}
                onChange={(value) => {
                  setForm({ ...form, message: value });
                  setError({ ...error, message: null });
                }}
              />
              <>
                {loading ? (
                  <div className="support-loading">
                    <Loading.Circle fullScreen width={60} height={60} />
                  </div>
                ) : (
                  <Button color="button" disabled={handleCheckForm()} width="160px">
                    Enviar
                  </Button>
                )}
              </>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
