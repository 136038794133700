//GLOBAL - components from npm
import React from 'react';

//STYLES
import './button.scss'

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function Button({ children, weight, className, color }) {


    return (
        <div className={`button-text button-text--${weight} ${className ? className : ''} button-text--${color}`}>
            {children}
        </div>
    )
}
