import validator from "validator";

export const validationPassword = (password) => {
  if (!validator.isStrongPassword(password, { minLength: 8, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 0})) {
    return "A senha precisa ter no mínimo 8 dígitos!";
  }else if (!validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 0, minNumbers: 0, minSymbols: 0})) {
    return "A senha precisa ter pelo menos uma letra minúscula!";
  }else if (!validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 0, minSymbols: 0})) {
    return "A senha precisa ter pelo menos uma letra maiúscula!";
  }else if (!validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0})) {
    return "A senha precisa ter pelo menos um número!";
  }else if (!validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1})) {
    return "A senha precisa ter pelo menos um símbolo!";
  }
  else{
    return "";
  }
}