//GLOBAL - components from npm
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

//STYLES
import "./redefine-password.scss";

//UTILS
import { validationPassword } from "../../utils/validation";

//COMPONENTS
import { Typography, Button } from "../../components/General";
import { Input } from "../../components/DataEntry";
import { Loading } from "../../components/Feedback";

//SERVICES - api, conectors...
import * as APIRedefinePassword from "../../services/api/redefinePassword";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconLock } from "../../assets/icons/Lock.svg";
import { ReactComponent as IconLogo } from "../../assets/icons/Logo.svg";
import check from "../../assets/images/check.png";
import Joystick from "../../assets/images/Joystick.png";

export default function RedefinePassword() {
  //GENERAL
  const { token } = useParams();

  //STATES
  const [pageStatus, setPageStatus] = useState("initial");
  const [passwordValue, setPasswordValue] = useState({ password: "", error: "" });
  const [confirmPasswordValue, setConfirmPasswordValue] = useState({ password: "", error: "" });
  const [isLoading, setIsLoading] = useState(true);

  //REDUX - Selectors

  //FUNCTIONS
  const getCheckToken = async () => {
    const data = {
      token: token,
    };

      const response = await APIRedefinePassword.checkToken(data);
      if (response.status === 200) {
        setPageStatus("initial");
        return setIsLoading(false);
      } else {
        setIsLoading(false);
        setPageStatus("error");
      }

  };

  const handleRedefinePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      password: passwordValue.password,
    };

      const response = await APIRedefinePassword.redefinePassword(token, data);

      if (response.status === 200) {
        setPageStatus("success");
        return setIsLoading(false);
      } else {
        setIsLoading(false);
        setPageStatus("error");
      }

  };

  //USE EFFECTS
  useEffect(() => {
    if (passwordValue.password.length > 0) {
      setPasswordValue({ ...passwordValue, error: validationPassword(passwordValue.password) });
    } else {
      setPasswordValue({ ...passwordValue, error: "" });
    }
  }, [passwordValue.password]);

  useEffect(() => {
    if (confirmPasswordValue.password.length > 0 && confirmPasswordValue.password !== passwordValue.password) {
      setConfirmPasswordValue({ ...confirmPasswordValue, error: "As senhas não conferem!" });
    } else {
      setConfirmPasswordValue({ ...confirmPasswordValue, error: "" });
    }
  }, [confirmPasswordValue.password, passwordValue.password]);

  useEffect(() => {
    getCheckToken();
  }, []);

  return (
    <div className="redefine-password">
      {!isLoading ? (
        <>
          <div className="redefine-password-header">
            <div className="redefine-password-header__icon">
              <IconLock />
            </div>
            <IconLogo />
          </div>
          {pageStatus === "initial" ? (
            <form className="redefine-password-main" onSubmit={(e) => handleRedefinePassword(e)}>
              <Typography.H1 color="white-gray-3">Redefinição de senha</Typography.H1>
              <Input
                leftIcon={<IconLock/>}
                typeIcon="fill"
                error={passwordValue.error}
                type="password"
                name="senha"
                placeholder="Digite uma nova senha"
                value={passwordValue.password}
                onChange={(value) => setPasswordValue({ ...passwordValue, password: value })}
              />
              <Input
                leftIcon={<IconLock/>}
                typeIcon="fill"
                error={confirmPasswordValue.error}
                type="password"
                name="confirmar-senha"
                placeholder="Confirmar a senha"
                value={confirmPasswordValue.password}
                onChange={(value) => setConfirmPasswordValue({ ...confirmPasswordValue, password: value })}
              />
              <Button
                color="button"
                width="160px"
                disabled={
                  confirmPasswordValue.error ||
                  passwordValue.error ||
                  passwordValue.password.length < 8 ||
                  confirmPasswordValue.password.length < 8
                }
              >
                Redefinir
              </Button>
            </form>
          ) : pageStatus === "success" ? (
            <div className="redefine-password-success">
              <Typography.H1 color="white-gray-3">Nova senha cadastrada</Typography.H1>
              <Typography.Text color="white-gray-5">Você já pode fazer o login com sua nova senha</Typography.Text>
              <img alt="check" src={check} />
            </div>
          ) : (
            <div className="redefine-password-error">
              <Typography.H1 color="white-gray-3">Redefinação de Senha</Typography.H1>
              <Typography.Text color="white-gray-5">
                Seu link de redefinição de senha expirou, já deve estar perdido em outra dimensão
              </Typography.Text>
              <img alt="joystick" src={Joystick} />
            </div>
          )}
        </>
      ) : (
        <Loading.Circle fullScreen/>
      )}
    </div>
  );
}
