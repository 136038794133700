import instance from "../configAxios";

export async function postSupport(data) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.post(`/contact`, data, config);
    return response;
  } catch (error) {
    return error
  }
}