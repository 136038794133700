//GLOBAL - components from npm
import React from 'react';

//STYLES
import './home.scss'

//COMPONENTS
import { Typography } from "../../components/General";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconLogo } from "../../assets/icons/Logo.svg";


export default function Home() {
    //GENERAL
    
    
    //STATES
    
    
    //REDUX - Selectors
    
    
    //FUNCTIONS
    
    
    //USE EFFECTS
    
    
    return (
        <div className="page-home">
            <IconLogo />
            <Typography.H1 color="primary-1">(Em breve)</Typography.H1>
        </div>
    )
}
