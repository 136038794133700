import instance from "../configAxios";

export async function redefinePassword(token, data) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.post(`/players/reset_password?token=${token}`, data, config);
    return response;
  } catch (error) {
    return error
  }
}

export async function checkToken(data) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.post('/players/check_session', data, config);
    return response;
  } catch (error) {
    return error
  }
}