import React from 'react';
import ReactDOM from 'react-dom';
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";

import "./styles/global.scss";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

