// GLOBAL - components from npm
import Lottie from 'react-lottie'

// STYLES
import './circle.scss'

// DATA
import animationData from './circle.json'

export default function Circle({ width = 100, height = 100, loop = true, autoplay = true, className, fullScreen }) {
    // GENERAL
    const defaultOptions = {
        loop: loop,
        autoplay: autoplay,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div className={`loading--circle ${className ? className : ''} ${fullScreen ? 'loading--fullscreen' : ''}`}>
            <Lottie
                options={defaultOptions}
                width={width}
                height={height}
            />
        </div>
    )
}