// GLOBAL - components from npm
import React from "react";

// STYLES
import "./button.scss";

// COMPONENTS
import { Typography } from "../../General";

export default function Button({
  color,
  children,
  onClick = () => null,
  icon,
  className,
  disabled,
  width,
}) {

  return (
    <button
      disabled={disabled}
      className={`button button--${color} ${disabled ? `button--disabled` : ""} ${className ? className : ""} `}
      onClick={() => onClick()}
      style={{width: width}}
    >
      {icon && <div className="button__icon">{icon}</div>}
      <Typography.Button color="white-gray">{children}</Typography.Button>
    </button>
  );
}
